import client from './client';

export interface FileData {
  id: number;
  filename: string;
  originalname: string;
}

export const getWatermarkURL = (filename: string) =>
  `${client.defaults.baseURL}/file/watermark/${filename}`;

export const uploadFile = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return client.post<FileData>('/file/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
