import client from './client';

export const login = ({ name, password }: { name: string; password: string }) =>
  client.post('/auth/login', { name, password });

export const logout = () => client.get('/auth/logout');

export const register = ({
  studentNumber,
  name,
  password,
}: {
  studentNumber: string;
  name: string;
  password: string;
}) => client.post('/auth/register', { studentNumber, name, password });

export const check = () => client.get('/auth/check');
