import React, { useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';

import Container from '../components/base/Container';
import PageTitle from '../components/base/PageTitle';
import LabelInput from '../components/common/LabelInput';
import Button from '../components/common/Button';
import { RootState } from '../modules';
import { setUser } from '../modules/user';
import { login } from '../lib/api/auth';
import Alert from '../components/common/Alert';

const LoginForm = styled.form`
  margin: 5rem 0;
  padding: 0 15vw;
`;

const ButtonSet = styled.div`
  text-align: center;
`;

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  setUser,
};

interface OwnProps extends RouteComponentProps {}
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type LoginPageProps = OwnProps & StateProps & DispatchProps;

const LoginPage: React.FC<LoginPageProps> = ({ history, setUser }) => {
  const [form, setValues] = useState({
    name: '',
    password: '',
  });
  const [alert, setAlert] = useState('');

  const updateField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...form, [event.target.name]: event.target.value });
  };

  const onSubmit = (event: React.FormEvent) => {
    login(form)
      .then(response => {
        const { id, email, username, admin } = response.data;
        setUser({
          id,
          studentNumber: email,
          name: username,
          admin,
        });
        history.push('/');

        ReactGA.event({
          category: 'auth',
          action: 'login',
          label: 'success'
        });
      })
      .catch((error) => {
        const { name } = error.response.data;
        if (name == 'WRONG_CREDENTIALS') {
          setAlert('이름 또는 비밀번호가 올바르지 않습니다.');
        } else if (name == 'NOT_OPEN') {
          setAlert('사이트는 2022년 06월 22일 18시 40분부터 열립니다.');
        } else {
          setAlert('알 수 없는 오류가 발생했습니다.');
        }
        setValues({ name: '', password: '' });

        ReactGA.event({
          category: 'auth',
          action: 'login',
          label: 'failure'
        });
      });

    event.preventDefault();
  };

  return (
    <Container>
      <PageTitle>로그인</PageTitle>

      <LoginForm onSubmit={onSubmit}>
        {alert && <Alert color="secondary">{alert}</Alert>}
        <LabelInput
          name="name"
          label="이름"
          value={form.name}
          onChange={updateField}
        />
        <LabelInput
          type="password"
          name="password"
          label="비밀번호"
          value={form.password}
          onChange={updateField}
        />
        <ButtonSet>
          <Button type="submit" size="large" onClick={onSubmit}>
            로그인
          </Button>
        </ButtonSet>
      </LoginForm>
    </Container>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPage);
