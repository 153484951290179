import React from 'react';
import Container from '../components/base/Container';

interface MainPageProps {}

const MainPage: React.FC<MainPageProps> = () => (
  <Container>
    <h2>전하는 글</h2>
    <p>
      부고 학생 여러분, 안녕하십니까? 3학년 자율동아리 BUNICEF입니다.
      학원이나 학교 선배 등의 도움을 통해 학교 기출을 얻어야 하는 능력 외적의 불공정한 상황을 타파하자는 취지 하에 과년도 학교 기출을 제공하고 있습니다.
      댓글로 문제에 대한 풀이나 생각을 나눌 수 있으니 많은 참여 부탁드립니다.
    </p>
    <p>
      <b>무엇보다도, 저작권 문제에 신경써주시기 바랍니다. </b>
      워터마크를 넣고, IP 제한을 하는 등 외부로 유출되지 않도록 여러 장치를 마련하였지만, <b>최종적으로는 여러분의 양심에 달려있습니다. </b>
      다른 학교나 학원 등의 외부에 이 사이트의 링크 혹은 이미지가 유출되는 일이 없도록 주의하시기 바랍니다.
      우리 학교 안에서도 이 사이트 내의 이미지를 서로 공유하기보다는 링크(https://bunicef.onnuri.dev)를 공유하는 것이 좋습니다.
    </p>
    <p>앞으로, 저희는 학교 기출 제공 뿐만 아니라 선배들이 작성하는 칼럼 게시판 등 여러 서비스를 제공할 예정이니 많은 관심 부탁드립니다.</p>
    <h2>자주 묻는 질문</h2>
    <h3>Q. 비밀번호가 뭔가요?</h3>
    <p>비밀번호는 학생 각자에게 제공되었습니다. 까먹었다면 관리자에게 연락 주면 새로 발급해드리겠습니다. <i><del>(제발 그럴 일 없도록 해주세요.)</del></i></p>

    <h3>Q. 로그인이 안돼요!</h3>
    <p>다음과 같은 이유가 있습니다.</p>
    <ul>
      <li>SDBG_WIFI가 아닌 와이파이를 사용하는 경우 (보안 상의 이유로 모바일 데이터나 다른 와이파이 등은 사용이 불가능합니다.)</li>
      <li>주소가 https://bunicef.onnuri.dev/가 아닌 경우</li>
      <li>카카오톡 내부 브라우저를 통해 접속하는 경우 (크롬 사용을 적극 권장합니다.)</li>
    </ul>
    이외에 해당하는 오류가 발생한다면 관리자에게 연락 바랍니다.
    
    <h3>Q. 회원가입이 없네요?</h3>
    <p>이미 2학년의 모든 학생을 대상으로 회원가입을 완료하였습니다. 회원가입 없이 로그인 해주시면 됩니다.</p>

    <h3>Q. 맞았는데 점수에 반영이 안돼요!</h3>
    <p>처음 맞은 것에만 점수를 부여합니다. 신중히 입력해주세요.</p>

    <h3>Q. 스마트폰에서 보면 화면이 깨져요!</h3>
    <p>폰 빨리 내라.</p>

    <h3>Q. 사이트에 버그가 있어요!</h3>
    <p>관리자에게 인스타 DM 주시면 됩니다.</p>

    <p>관리자 연락처 : 인스타 DM(<a href="https://www.instagram.com/nanyanunyo/">@nanyanunyo</a>)</p>
  </Container>
);

export default MainPage;
