import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';

import { RootState } from '../../modules';
import { closeChallModal } from '../../modules/chall';
import LabelInput from '../common/LabelInput';
import palette from '../../lib/styles/palette';
import { authChall, createComment, viewComments, removeComment } from '../../lib/api/chall';
import Alert from '../common/Alert';
import Badge from '../common/Badge';
import { getWatermarkURL, uploadFile } from '../../lib/api/file';
import LabelTextArea from '../common/LabelTextArea';
import Button from '../common/Button';
import { FaImage } from 'react-icons/fa';
import { timeToInterval } from '../../lib/utils';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.16);
`;

const ChallModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  height: 80vh;
  padding: 2rem;
  overflow: scroll;

  background-color: white;

  .name,
  .points,
  .tag-list {
    text-align: center;
  }

  .name {
    font-size: 2em;
    margin: 0.5rem 0;
  }

  .points {
    font-size: 1.4em;
    margin: 0;
  }

  .tag-list {
    padding: 1rem 0;
  }

  .problem-img {
    width: 100%;
  }

  .comment-section {
    background: #eee;
    border-radius: 3px;

    margin: 0;
    padding: 0.5rem 0.3rem 0 0.3rem;

    .control-bar {
      text-align: right;

      label {
        margin-right: 0.5rem;
        color: ${palette.gray500};
        cursor: pointer;
      }

      .image-filename {
        margin-right: 0.5rem;
      }
    }

    .comment-unavailable {
      padding: 0.5rem 0.5rem 0.8rem 0.5rem;
    }
  }

  #image-file {
    display: none;
  }
`;

const CommentItem = styled.div`
  padding: 0.5rem 0.3rem;
  border-bottom: 1px solid gray;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .author {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    .comment-control {
      .remove {
        margin-right: 0.3rem;
        cursor: pointer;
      }

      .createdAt {
        color: ${palette.gray500};
        font-style: italic;
      }
    }
  }  

  .image img {
    width: 100%;
  }
`;

const mapStateToProps = (state: RootState) => ({
  modalChall: state.chall.modalChall,
  user: state.user.user
});

const mapDispatchToProps = { closeChallModal };

interface OwnProps {}
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type ChallModalProps = OwnProps & StateProps & DispatchProps;

interface Comment {
  id: number;
  content: string;
  createdAt: string;
  user: {
    id: number;
    studentNumber: string;
    name: string;
  };
  image: {
    id: number;
    filename: string;
  } | null;
}

const ChallModal: React.FC<ChallModalProps> = ({
  modalChall,
  closeChallModal,
  user,
}) => {
  const [flag, setFlag] = useState('');
  const [authState, setAuthState] = useState('INITIAL');

  const [comment, setComment] = useState('');
  const [filename, setFilename] = useState('');

  const [comments, setComments] = useState<Comment[]>([]);
  const [isCommentAvailable, setCommentAvailable] = useState(false);

  const fileRef = useRef<HTMLInputElement>(null);

  const updateComments = () => {
    if (modalChall) {
      viewComments(modalChall.id).then(res => {
        setComments(res.data.map(comment => ({
            id: comment.id,
            content: comment.content,
            createdAt: comment.createdAt,
            user: {
              id: comment.user.id,
              studentNumber: comment.user.studentNumber,
              name: comment.user.name,
            },
            image: comment.image ? {
              id: comment.image.id,
              filename: comment.image.filename,
            } : null
          })));
        setCommentAvailable(true);
      }).catch(() => {
        setCommentAvailable(false);
      })
    }
  }

  useEffect(() => {
    updateComments();
  }, [modalChall]);

  if (!modalChall) {
    return null;
  }

  const onSubmit = (event: React.FormEvent) => {
    authChall(modalChall.id, flag)
      .then(() => {
        setAuthState('SUCCESS');
        ReactGA.event({
          category: 'challenge',
          action: 'auth',
          label: 'success'
        });
      })
      .catch(() => {
        setAuthState('FAIL');
        ReactGA.event({
          category: 'challenge',
          action: 'auth',
          label: 'failure'
        });
      });

    setFlag('');

    event.preventDefault();
  };

  const onFileChange = () => {
    const file = fileRef.current?.files?.[0];

    if (file) {
      setFilename(file.name);
    }
  };

  const onCommentSubmit = () => {
    const file = fileRef.current?.files?.[0];

    if (file) {
      uploadFile(file)
        .then(res => createComment(comment, modalChall.id, res.data.id))
        .then(() => {
          updateComments();
        });

      ReactGA.event({
        category: 'challenge',
        action: 'create_comment',
        label: 'with_image',
      });
    } else {
      createComment(comment, modalChall.id, null)
        .then(() => {
          updateComments();
        });

      ReactGA.event({
        category: 'challenge',
        action: 'create_comment',
        label: 'without_image',
      });
    }

    setComment('');
    setFilename('');
  };

  const onCommentRemove = (id: number) => {
    removeComment(id)
      .then(() => {
        updateComments();
      });
    
    ReactGA.event({
      category: 'challenge',
      action: 'remove_comment',
    });
  };

  const { name, points, description, author, files, tags, solved } = modalChall;

  return (
    <>
      <ModalOverlay
        onClick={() => {
          setFlag('');
          setAuthState('INITIAL');
          closeChallModal();
        }}
      />
      <ChallModalContainer>
        <h2 className="name">{name}</h2>
        <h3 className="points">
          {points}
          pts
        </h3>
        {tags && (
          <div className="tag-list">
            {tags.map(tag => (
              <Badge key={tag.name}>{tag.name}</Badge>
            ))}
            {solved && <Badge bgColor={palette.primary600}>맞은 문제</Badge>}
          </div>
        )}
        <p className="description">{description}</p>
        {files &&
          files.map(({ filename }) => (
            <img
              className="problem-img"
              src={getWatermarkURL(filename)}
              alt=""
            />
          ))}
        {author && <p className="author">{`출처: ${author}`}</p>}
        <form onSubmit={onSubmit}>
          <LabelInput
            label="정답"
            value={flag}
            onChange={event => setFlag(event.target.value)}
          />
        </form>
        {authState === 'SUCCESS' ? (
          <Alert color="primary">맞았습니다!</Alert>
        ) : (
          authState === 'FAIL' && <Alert color="secondary">틀렸습니다!</Alert>
        )}

        <div className="comment-section">
          {
            isCommentAvailable ? (
              <>
                <div className="comment-write">
                  풀이나 궁금한 점을 나눠보세요!
                  <LabelTextArea
                      name="comment"
                      label="댓글"
                      value={comment}
                      onChange={e => setComment(e.target.value)}
                    />
                  <div className="control-bar">
                    <span className="image-filename">{filename}</span>

                    <label htmlFor="image-file">
                      <FaImage />
                      <input type="file" id="image-file" onChange={onFileChange} ref={fileRef}/>
                    </label>
                    
                    <Button type="button" size="medium" onClick={onCommentSubmit}>
                      작성
                    </Button>
                  </div>
                </div>
                <hr/>
                <div className="comment-view">
                  {
                    comments.map(comment => (
                      <CommentItem key={comment.id}>
                        <div className="header">
                          <span className="author">{`${comment.user.studentNumber} ${comment.user.name}`}</span>
                          <div className="comment-control">
                            {
                              user?.id === comment.user.id && (<span className="remove" onClick={() => onCommentRemove(comment.id)}>삭제</span>)
                            }
                            <span className="createdAt">{`${timeToInterval(new Date(comment.createdAt))}`}</span>
                          </div>
                        </div>
                        <div className="content">{comment.content}</div>
                        <div className="image">
                          {comment.image && (
                            <a href={getWatermarkURL(comment.image.filename)} target="_blank">
                              <img src={getWatermarkURL(comment.image.filename)} alt="" />
                            </a>
                          )}
                        </div>
                      </CommentItem>
                    ))
                  }
                </div>
              </>
            ) : (
              <div className="comment-unavailable">
                정답 여부와 관계 없이 제출을 적어도 한 번 해야 댓글을 보거나 작성할 수 있습니다.
              </div>
            )
          }
        </div>
      </ChallModalContainer>
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(ChallModal);
