import React from 'react';
import styled from 'styled-components';
import Container from './Container';

const FooterContainer = styled.div`
  text-align: center;
  padding: 1rem;

  font-weight: bold;
`;

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => (
  <Container>
    <FooterContainer>
      어떤 방식으로든 본 사이트의 외부 유출을 엄격하게 금지합니다.
    </FooterContainer>
  </Container>
);

export default Footer;
